import React, { Component } from 'react';
import ModalGeneTestForm from '../modal-gene-test-form';
import genetic_testing_api from '../../api/genetic-testing-api';
import geneResults from '../../assets/json/gene-result.json';
import geneStatus from '../../assets/json/gene-status.json';
import ModalConfirmDelete from '../modal-confirm-delete';
import { createUUID } from './utils';
import { isEmpty } from 'lodash';
import { connect } from "react-redux";
import * as static_list_actions from "../../store/static_list/actions";
import EditCustomPanel from '../customize-gene-panels';
import umlsApi from '../../api/umls-api';

class PatientGeneTestView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalGeneTest: false,
      selectedGeneDetail: null,
      modalGeneTestTitle: '',
      openModalConfirmDelete: false,
      modalConfirmDeleteHash: null,
      loading_delete: false,
      errorMessages: [],
      showCustomGenePanel: false,
      totalGenesChecked: 0,
      verificationComplete: false,
      matchingCustomGenes: [],
      unmatchedCustomGenes: [],
    };

    this.handleClickAddGeneTest = this.handleClickAddGeneTest.bind(this);
    this.getGeneTests = this.getGeneTests.bind(this);
    this.diseaseGroup = this.diseaseGroup.bind(this);
    this.handleEditGeneTest = this.handleEditGeneTest.bind(this);
    this.getLabelResult = this.getLabelResult.bind(this);
    this.getLabelStatus = this.getLabelStatus.bind(this);
    this.onModalGeneTestSave = this.onModalGeneTestSave.bind(this);
    this.onModalGeneTestDelete = this.onModalGeneTestDelete.bind(this);
    this.onClickOpenModalConfirmDelete = this.onClickOpenModalConfirmDelete.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.disableAddForReadOnly = this.disableAddForReadOnly.bind(this);
    this.renderGenes = this.renderGenes.bind(this);
    this.getGenePanels =  this.getGenePanels.bind(this);
    this.saveNewGenePanel = this.saveNewGenePanel.bind(this);
    this.clearCustomGeneContent = this.clearCustomGeneContent.bind(this);
  }

  async componentDidMount() {
    const updatedPanels = false
    await this.loadGenePanelsFromAPI(updatedPanels)
  }


  async handleClickAddGeneTest() {
    if('onBeforeAddGene' in this.props) {
      var ok = await this.props.onBeforeAddGene();
      if(ok) {
        this.setState({showModalGeneTest: true, selectedGeneDetail: null, modalGeneTestTitle: 'Add Gene'});
      }
    } else {
      this.setState({showModalGeneTest: true, selectedGeneDetail: null, modalGeneTestTitle: 'Add Gene'});
    }
  }

  getGeneTests() {
    const genes = this.props.getPedigreeData().getGenes(this.props.patientRkey);
    for(let i=0; i<genes.length; i++) if(genes[i].rkey === null || genes[i].rkey === undefined) genes[i].rkey = createUUID();
    return genes;
  }

  diseaseGroup(id) {
    if(1 <= id <= 43) {
      return 'Cancer';
    } else {
      return 'Other Conditions';
    }
  }
  async loadGenePanelsFromAPI(updated = false) {
    if(!isEmpty(this.props.static_list.genetic_testin_panels) && !updated) return;
      try{
        let labs = await genetic_testing_api.get_genetic_testing_labs({clinician_id: this.props.user.clinician_id})
        let panels = await genetic_testing_api.get_gene_panels({clinician_id: this.props.user.clinician_id})
        await this.props.dispatch(static_list_actions.initialize_genetic_testing_panels(panels));
        await this.props.dispatch(static_list_actions.initialize_genetic_testing_labs(labs));
      } catch (error) {
        console.log(error);
    }
  }

  getPanelName(panelId) {
    const panels = this.props.static_list.genetic_testin_panels;
    if (!panels) return panelId;
  
    const panel = panels[panelId];
    return panel?.panel_name || panelId;
  }

  getGenePanels() {
    const genePanels = this.props.getPedigreeData().getGenePanels(this.props.patientRkey);
    return genePanels;
  }

  handleEditGeneTest(selectedGeneDetail) {
    this.setState({showModalGeneTest: true, selectedGeneDetail, modalGeneTestTitle: 'Edit Gene'});
  }

  getLabelResult(id) {
    if(geneResults.length == 0) return '';
    var result = geneResults.find(item => item.value == id || item.label == id)

    if(typeof(result) === 'undefined') return ''
    return result.label + ', ';
  }

  getLabelStatus(id) {
    if(geneStatus.length == 0) return '';
    var status = geneStatus.find(item => item.value == id)

    if(typeof(status) === 'undefined') return ''
    return status.label;
  }

  async onModalGeneTestSave(data) {
   
    if('onGeneTestUpdate' in this.props) {
      await this.props.onGeneTestUpdate(data);
      this.props.updateSubtextMargin()
    }
    this.setState({showModalGeneTest: false});
  }

  async onModalGeneTestDelete(data) {
    if('onGeneTestDelete' in this.props){
      await this.props.onGeneTestDelete(data);
      this.props.updateSubtextMargin()
    }
    this.setState({showModalGeneTest: false});
    if(this.state.openModalConfirmDelete === true) this.setState({openModalConfirmDelete: false})
  }

  onClickOpenModalConfirmDelete(selectedGeneDetail) {
    this.setState({
      selectedGeneDetail,
      openModalConfirmDelete: true,
      modalConfirmDeleteHash: new Date().getTime()
    });
  }

  async onClickDelete() {
    try {
      this.setState({ errorMessages: [], loading_delete: true })
      let genePayload = {}
      // delete gene panel
      if(this.state.selectedGeneDetail.testing_panel) {
        genePayload = {
          member_id: this.state.selectedGeneDetail.member_id,
          gene_rkey: this.state.selectedGeneDetail.rkey ? this.state.selectedGeneDetail.rkey : null,
          testing_panel: this.state.selectedGeneDetail.testing_panel
      }
      await genetic_testing_api.delete_member_gene_panel(genePayload)

    }
      // delete genetic testing
      else if(this.state.selectedGeneDetail.id !== null) {
        await genetic_testing_api.delete_member_genetic_testing_id(this.state.selectedGeneDetail.id)
      }

      if('onGeneTestDelete' in this.props) {
        this.onModalGeneTestDelete({
          rkey: this.props.patientRkey,
          gene_rkey: this.state.selectedGeneDetail.rkey ? this.state.selectedGeneDetail.rkey : null,
          testing_panel: this.state.selectedGeneDetail.testing_panel ? this.state.selectedGeneDetail.testing_panel : null
        });
      } else {
        this.setState({showModalGeneTest: false})
      }

    } catch (error) {
      this.setState({ errorMessages: [error.message] })
    } finally {
      this.setState({ loading_delete: false })
    }
    this.setState({openModalConfirmDelete: false})
  }

  disableAddForReadOnly(){
    if(this.props.read_only && this.props.read_only === true){
      return false
    }
    return true
  }

  async saveNewGenePanel(data){
    const updatedPanels = true
    try{
      let organization_id = this.props.user.organization_id
      data['organization_id'] = organization_id
      await genetic_testing_api.save_new_gene_panel(data)
      
    }catch(error){
      console.log(error)
    }finally{
      await  this.loadGenePanelsFromAPI(updatedPanels)
      this.setState({showCustomGenePanel: false})
    }
  
  }

  clearCustomGeneContent(){
    this.setState({
      showCustomGenePanel: false, 
      totalGenesChecked: 0, 
      matchingCustomGenes: [], 
      unmatchedCustomGenes: []
      
    })
  }

  renderGenes(){
      const geneTests = this.getGenePanels();

      if(isEmpty(geneTests)) return <> </>
        return Object.keys(geneTests).map(key => {
          const tests = geneTests[key]['member_genes'];
          const panel_name = this.getPanelName(geneTests[key]['panel_id']);
          const positiveResults = Object.keys(tests).filter(key => (tests[key].result === 'p' || tests[key].result === 'Pathogenic')).map(key => tests[key]);
          return (panel_name !== '' && panel_name !== undefined && panel_name !== null) ? (
              <div 
                className={`panel-summary ${this.props.sideBar ? 'width--full' : ''}`}
              >
                {this.disableAddForReadOnly() && (
                  <div className="panel-summary-actions">
                    <a 
                      className="panel-summary-edit" 
                      onClick={() => this.handleEditGeneTest(geneTests[key])}
                    >
                      <i className="fa fa-pencil-square-o"></i>
                    </a>
                    <a 
                      className="panel-summary-delete" 
                      onClick={() => this.onClickOpenModalConfirmDelete(geneTests[key])}
                    >
                      <i className="fa fa-trash"></i>
                    </a>
                  </div>
                )}
  
                <h4>{panel_name}</h4>
                {positiveResults.length > 0 ? (
                  positiveResults.map(({ hash_id, gene, umls_name, result, variant, status }) => (
                    <p key={hash_id}>
                      <b> {gene || umls_name} </b> - {this.getLabelResult(result)}
                      {variant && `${variant}, `}
                      {this.getLabelStatus(status || null)}
                    </p>
                  ))
                ) : (
                  <p>Negative Genetic Testing</p>
                )}
              </div>
  
          ) : (
            <div 
            className={`panel-summary ${this.props.sideBar ? 'width--full' : ''}`}
            >
                {this.disableAddForReadOnly() && (
                  <div className="panel-summary-actions">
                    <a 
                      className="panel-summary-edit" 
                      onClick={() => this.handleEditGeneTest(geneTests[key])}
                    >
                      <i className="fa fa-pencil-square-o"></i>
                    </a>
                    <a 
                      className="panel-summary-delete" 
                      onClick={() => this.onClickOpenModalConfirmDelete(geneTests[key])}
                    >
                      <i className="fa fa-trash"></i>
                    </a>
                  </div>
                )}              
              {Object.values(tests).map((gene) => (
                  <p>
                    <b>{gene.umls_name || gene.gene}</b> - {this.getLabelResult(gene.result)}
                    {gene.variant && `${gene.variant}, `}
                    {this.getLabelStatus(gene.status || null)}
                  </p>
              ))}              

            </div>
            
            )
        });
    }

  render() {
    return (
      <React.Fragment>
        <div className="row margin-four-bottom">
          <div className="col-md-12 margin-one no-margin-top">
            <div className={(this.props.sideBar ? "form-header-2" : "form-header")}>
              <div className="row vcenter">
                <div className="col-md-6 ">
                <h3 className={"title " + (this.props.sideBar ? 'text--uppercase' : '')}>Genetic Testing</h3>
                </div>
                  {this.disableAddForReadOnly() && (
                    <div className="col-md-6 ">

                      {this.props.sideBar ?

                      <div className='inline-gene-buttons pull-right'>
                        <a className="btn btn-teal btn-xs" onClick={this.handleClickAddGeneTest}><i className="fa fa-plus"></i> Add</a>

                        <span className='i-tooltip-column'>
                          <a onClick={() => this.setState({ showCustomGenePanel: true })}>
                          <i className="fa fa-cog" style={{marginTop: '8px', paddingRight: '4px'}}></i>
                          </a>
                        
                          <span className="i-tooltiptext-column gene-panel-text">
                                Add/Edit Custom Tests
                                </span>
                              </span>
                           
                        </div>

                      :
                      <div className='inline-gene-buttons pull-right'>
                      <span className='i-tooltip-column'>
                        <a onClick={() => this.setState({ showCustomGenePanel: true })}>
                        <i className="fa fa-cog" style={{marginTop: '8px'}}></i>

                        </a>
                      

                      <a className="btn btn-teal btn-xs" onClick={this.handleClickAddGeneTest}><i className="fa fa-plus"></i> Add</a>
                          <span className="i-tooltiptext-column gene-panel-text">
                              Add/Edit Custom Tests
                              </span>
                            </span>
                      </div>

                      }

                    </div>
                  )}
                </div>
            </div>
            <div className="panel-col-gene">

              {this.renderGenes()}
              {this.getGeneTests().map(item =>
                <div key={item.rkey} className={"panel-summary " + (this.props.sideBar ? 'width--full' : '')}>
                {this.disableAddForReadOnly() && (
                  <>
                  <a className="panel-summary-edit" onClick={() => this.handleEditGeneTest(item)}><i className="fa fa-pencil-square-o"></i></a>
                  <a className="panel-summary-delete" onClick={() => this.onClickOpenModalConfirmDelete(item)}><i className="fa fa-trash"></i></a>
                  </>
                )}
                  <p>
                    <b>{item.gene || item.umls_name}</b> - {this.getLabelResult(item.result)}
                    {item.variant && `${item.variant}, `}
                    {this.getLabelStatus(item.status || null)}
                  </p>
                </div>
              )}

            </div>
          </div>
        </div>

        {this.state.openModalConfirmDelete &&
          <ModalConfirmDelete
            title="Delete Gene"
            message="Do you want to delete this gene?"
            isOpen={this.state.openModalConfirmDelete}
            onCancel={() => this.setState({ openModalConfirmDelete: false })}
            onOk={() => this.onClickDelete()}
            loading={this.state.loading_delete}
            errorMessages={this.state.errorMessages}
          />
        }

        {this.state.showModalGeneTest &&
          <ModalGeneTestForm
            title={this.state.modalGeneTestTitle}
            patientRkey={this.props.patientRkey}
            profile={this.props.profile}
            geneDetail={this.state.selectedGeneDetail}
            onClose={() => this.setState({showModalGeneTest: false})}
            onCancel={() => this.setState({showModalGeneTest: false})}
            onSave={(data) => this.onModalGeneTestSave(data)}
            onDelete={(data) => this.onModalGeneTestDelete(data)}
            openEditCustomPanel={() => this.setState({showCustomGenePanel: true, showModalGeneTest: false})}
            getPedigreeData={this.props.getPedigreeData}
          />
        }
        {this.state.showCustomGenePanel &&
          <EditCustomPanel
            onClose={() =>this.clearCustomGeneContent()}
            matchingCustomGenes={this.state.matchingCustomGenes}
            unmatchedCustomGenes={this.state.unmatchedCustomGenes}
            totalGenesChecked={this.state.totalGenesChecked}
            validatedGenes={this.state.validatedGenes}
            genetic_testing_panels={this.props.static_list.genetic_testin_panels}
            gene_labs={this.props.static_list.genetic_testings_labs}
            onSave={(data)=>this.saveNewGenePanel(data)}
            organization_id={this.props.user.organization_id}
          />

        }



      </React.Fragment>
    )
  }
}

const redux_state = state => ({
  static_list: state.static_list,
  user: state.session.user
});
export default connect(redux_state)(PatientGeneTestView);
