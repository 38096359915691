import React, { Component } from 'react';

import LabelField from './fields/label-field';
import TextShortField from './fields/text-short-field';
import TextLongField from './fields/text-long-field';
import NumberField from './fields/number-field';
import YesOrNoField from './fields/yes-or-no-field';
import SelectOneField from './fields/select-one-field';
import SelectAllThatApplyField from './fields/select-all-that-apply-field';
import PhoneField from './fields/phone-field';
import DateField from './fields/date-field';
import ToggleField from './fields/toggle-field';

import { cloneDeep } from 'lodash';

import i18n from 'i18next';
import { createUUID } from '../../../react-flow-pedigree/utils';

const field_types = {
  LABEL: 'label',
  TEXT_SHORT: 'text_short',
  TEXT_LONG: 'text_long',
  INTEGER: 'integer',
  FLOAT: 'float',
  YES_OR_NO: 'yes_or_no',
  SELECT_ALL_THAT_APPLY: 'select_all_that_apply',
  SELECT_ONE: 'select_one',
  PHONE: 'phone',
  LONG_LIST_SEARCH: 'long_list_search',
  DATE: 'date',
  TOGGLE: 'toggle',
};

class PatientSurveyFieldCanvas extends Component {
  constructor(props) {
    super(props);

    this.categoryJSX = this.categoryJSX.bind(this);
    this.buildContent = this.buildContent.bind(this);
    this.renderCategory = this.renderCategory.bind(this);
    this.renderWorkflow = this.renderWorkflow.bind(this);
    this.renderField = this.renderField.bind(this);
    this.checkPrerequisites = this.checkPrerequisites.bind(this);
    this.checkMemberPrerequisites = this.checkMemberPrerequisites.bind(this);
    this.checkDiseasePrerequisites = this.checkDiseasePrerequisites.bind(this);
    this.createSelectAllAnswersArrray = this.createSelectAllAnswersArrray.bind(this)
  }

  categoryJSX(category_name, category_content) {
    return (
      // <div key={createUUID()} className="clinician-survey-category">
      //   <h3 key={createUUID()} className="title margin-two-bottom">
      <div key={`${category_name}_1`} className="clinician-survey-category">
        <h3 key={`${category_name}_2`} className="title margin-two-bottom">
          {category_name}
        </h3>
        {category_content}
      </div>
    );
  }

  buildContent() {
    const survey = cloneDeep(this.props.survey);

    let map = {};
    survey.survey_categories.forEach((item) => {
      const key = item.page_num;
      let collection = map[key];
      if (!collection) {
        map[key] = [item];
      } else {
        collection.push(item);
        map[key] = collection;
      }
    });

    // sorts based off the page number, thats what the zero slot is
    const grouped_categories = Object.entries(map).sort(function (a, b) {
      return a[0] - b[0];
    });

    // loop through the categories and build the survey with the components
    let column_one = [];
    let column_two = [];
    let add_to_column_one = true;
    let add_to_column_two = false;
    for(let categories of grouped_categories) {
      const sorted_categories = categories[1].sort(function (a, b) {
        return a.order - b.order;
      });

      for(let category of sorted_categories) {
        const category_content = this.renderCategory(category);

        const translation_key = category.translation_key + ".name";
        if (Array.isArray(category_content) && category_content.length > 0) {
          if (add_to_column_one) {

            add_to_column_one = false;
            add_to_column_two = true;
            if (category.category_name) {

              let category_name_translation = i18n.t(translation_key);
              if (category_name_translation === translation_key) {
                category_name_translation = category.category_name;
              }

              column_one.push(
                this.categoryJSX(category_name_translation, category_content)
              );
            } else {
              column_one.push(category_content);
            }

          } else if (add_to_column_two) {

            add_to_column_one = true;
            add_to_column_two = false;
            if (category.category_name) {

              let category_name_translation = i18n.t(translation_key);
              if (category_name_translation === translation_key) {
                category_name_translation = category.category_name;
              }

              column_two.push(
                this.categoryJSX(category_name_translation, category_content)
              );
            } else {
              column_two.push(category_content);
            }

          }
        }

      }
    }

    let page_content = (
      <div className="row">
        <div className="col-md-6">
          {column_one}
        </div>

        <div className="col-md-6">
          {column_two}
        </div>
      </div>
    );
    return page_content;
  }

  renderCategory(category) {
    // sort the question workflows by order
    const question_workflows = category.question_workflows.sort(function (a, b) {
      return a.order - b.order;
    });

    let category_content = [];
    for(let question_workflow of question_workflows) {
      const content = this.renderWorkflow(question_workflow);
      if (content) category_content.push(content);
    }

    return category_content;
  }

  renderWorkflow(question_workflow) {
    const master_question = question_workflow.master_question;

    // // in the provider portal we render the field regardless of the show flag
    // const show = question_workflow.show;
    //
    // return (show) ? this.renderField(master_question) : null;
    return this.renderField(master_question);
  }

  renderField(master_question) {
    const answers = this.props.completedSurveyAnswers.filter((answer) => {
      return parseInt(answer.master_question_id, 10) === parseInt(master_question.id, 10);
    });

    let did_pass_question_prerequisites = this.checkPrerequisites(master_question);
    let did_pass_member_question_prerequisites = this.checkMemberPrerequisites(master_question);
    let did_pass_disease_question_prerequisites = this.checkDiseasePrerequisites(master_question);

    if (!did_pass_question_prerequisites ||
        !did_pass_member_question_prerequisites ||
        !did_pass_disease_question_prerequisites) {
      // delete all the answers since this field is no longer triggered
      for (const answer of answers) {
        this.props.deleteCompletedSurveyAnswer(answer);
      }

      return null;
    }

    const field_type = master_question.type;

    if (field_type === field_types.LABEL) {

      return (<LabelField
                key={createUUID()}
                masterQuestion={master_question}
              />);

    } else if (field_type === field_types.TEXT_SHORT) {

      return (<TextShortField
                key={createUUID()}
                masterQuestion={master_question}
                answer={(answers.length === 1) ? answers[0] : null}
                saveCompletedSurveyAnswer={(payload) => this.props.saveCompletedSurveyAnswer(payload)}
              />);

    } else if (field_type === field_types.TEXT_LONG) {

      return (<TextLongField
                key={createUUID()}
                masterQuestion={master_question}
                answer={(answers.length === 1) ? answers[0] : null}
                saveCompletedSurveyAnswer={(payload) => this.props.saveCompletedSurveyAnswer(payload)}
              />);

    } else if (field_type === field_types.INTEGER || field_type === field_types.FLOAT) {

      return (<NumberField
                key={createUUID()}
                masterQuestion={master_question}
                answer={(answers.length === 1) ? answers[0] : null}
                saveCompletedSurveyAnswer={(payload) => this.props.saveCompletedSurveyAnswer(payload)}
              />);

    } else if (field_type === field_types.YES_OR_NO) {

      return (<YesOrNoField
                key={createUUID()}
                masterQuestion={master_question}
                answer={(answers.length === 1) ? answers[0] : null}
                saveCompletedSurveyAnswer={(payload) => this.props.saveCompletedSurveyAnswer(payload)}
                deleteCompletedSurveyAnswer={(answer) => this.props.deleteCompletedSurveyAnswer(answer)}
              />);

    } else if (field_type === field_types.SELECT_ALL_THAT_APPLY) {

      return (<SelectAllThatApplyField
                key={createUUID()}
                masterQuestion={master_question}
                answers={answers}
                saveCompletedSurveyAnswer={(payload) => this.props.saveCompletedSurveyAnswer(payload)}
              />);

    } else if (field_type === field_types.SELECT_ONE) {

      return (<SelectOneField
                key={createUUID()}
                masterQuestion={master_question}
                answer={(answers.length === 1) ? answers[0] : null}
                saveCompletedSurveyAnswer={(payload) => this.props.saveCompletedSurveyAnswer(payload)}
                deleteCompletedSurveyAnswer={(answer) => this.props.deleteCompletedSurveyAnswer(answer)}
              />);

    } else if (field_type === field_types.PHONE) {

      return (<PhoneField
                key={createUUID()}
                masterQuestion={master_question}
                answer={(answers.length === 1) ? answers[0] : null}
                saveCompletedSurveyAnswer={(payload) => this.props.saveCompletedSurveyAnswer(payload)}
              />);

    } else if (field_type === field_types.DATE) {

      return (<DateField
                key={createUUID()}
                masterQuestion={master_question}
                answer={(answers.length === 1) ? answers[0] : null}
                saveCompletedSurveyAnswer={(payload) => this.props.saveCompletedSurveyAnswer(payload)}
              />);

    } else if (field_type === field_types.TOGGLE) {

      return (<ToggleField
                key={createUUID()}
                masterQuestion={master_question}
                answer={(answers.length === 1) ? answers[0] : null}
                saveCompletedSurveyAnswer={(payload) => this.props.saveCompletedSurveyAnswer(payload)}
              />);

    }

    return null;
  }

  createSelectAllAnswersArrray(pre_req_answers, master_question_prerequisite_id){
    let selectAll = []
    let answer = null
    for (const prerequisite_answer of pre_req_answers){
      if (prerequisite_answer.master_question_choice_id) {
        const master_question_choice_id = parseInt(prerequisite_answer.master_question_choice_id, 10);
        // get choices for master_question_prerequisite_id
        for (const cat of this.props.survey.survey_categories) {
          for (const question_workflow of cat.question_workflows) {
            if (master_question_prerequisite_id === parseInt(question_workflow.master_question.id, 10)) {
              const choices = question_workflow.master_question.master_question_choices;

              answer = choices.find((c) => {
                return parseInt(c.id, 10) === master_question_choice_id;
              });
              if (answer) {
                answer = answer.value;
                selectAll.push(answer);
              }
            }
          }
        }
      }
    }

    return selectAll

  }

  checkPrerequisites(master_question) {
    for (const prerequisite of master_question.question_prerequisites)  {
      const master_question_prerequisite_id = parseInt(prerequisite.master_question_prerequisite_id, 10);

      const prerequisite_answers = this.props.completedSurveyAnswers.filter((answer) => {
        return parseInt(answer.master_question_id, 10) === master_question_prerequisite_id;
      });

      if (prerequisite_answers.length === 0) {
        // if they don't have any answers then fail and return
        return false;
      }

      if (prerequisite_answers.length > 1) {
        let selectAllAnswerArray = [];
        selectAllAnswerArray = this.createSelectAllAnswersArrray(prerequisite_answers, master_question_prerequisite_id)

        //for skip logics with select all field
        //Add all the answers to an array and check if pre req field is included in answers array
        //Only time length would be > 1 would be if for select all field which uses the == operator

          if (selectAllAnswerArray.length === 0) {
            return false;
          }

          if (prerequisite.operator === "==") {

            if (!selectAllAnswerArray.includes(prerequisite.value) ) {
              return false;
            }

          } else if (prerequisite.operator === "IN") {

            if (typeof(prerequisite.value) === "string") {

              // prerequisite_answer is the probands answer for the question
              // choice is the choice from the master question choices that matched the prerequisite_answer.master_question_choice_id
              const values_array = prerequisite.value.split(",");
              for(var i=0; i<selectAllAnswerArray.length; i++) {
                if (values_array.includes(selectAllAnswerArray[i]) ) {
                  return true;
                }
              }
              return false;

            } else {
              // for some reason the prerequisite value is not a string
              // that can be split into an array
              return false;
            }

          } else if (prerequisite.operator === "NOT IN") {

            if (typeof(prerequisite.value) === "string") {

              // prerequisite_answer is the probands answer for the question
              // choice is the choice from the master question choices that matched the prerequisite_answer.master_question_choice_id
              const values_array = prerequisite.value.split(",");
              for(var i=0; i<selectAllAnswerArray.length; i++) {
                if (values_array.includes(selectAllAnswerArray[i]) ) {
                  return false;
                }
              }

            } else {
              // for some reason the prerequisite value is not a string
              // that can be split into an array
              return false;
            }

          } else {
            // prerequisite operator does not equal one that we recognize
            return false;
          }

        }


      if (prerequisite_answers.length === 1) {
        const prerequisite_answer = prerequisite_answers[0];
      // for (const prerequisite_answer of prerequisite_answers) {

        let answer = null;
        if (prerequisite_answer.master_question_choice_id) {
          const master_question_choice_id = parseInt(prerequisite_answer.master_question_choice_id, 10);

          // get choices for master_question_prerequisite_id
          let break_loops = false;
          for (const cat of this.props.survey.survey_categories) {
            for (const question_workflow of cat.question_workflows) {
              if (master_question_prerequisite_id === parseInt(question_workflow.master_question.id, 10)) {
                const choices = question_workflow.master_question.master_question_choices;

                answer = choices.find((c) => {
                  return parseInt(c.id, 10) === master_question_choice_id;
                });

                if (answer) {
                  answer = answer.value;
                  break_loops = true;
                  break;
                }

              }
            }

            if (break_loops) break;
          }
        } else {
          answer = prerequisite_answer.answer;
        }

        if (!answer) {
          return false;
        }

        if (prerequisite.operator === "==") {

          if (answer !== prerequisite.value) {
            return false;
          }

        } else if (prerequisite.operator === "!=") {

          if (answer === prerequisite.value ) {
            return false;
          }

        } else if (prerequisite.operator === ">") {

          if (parseInt(answer, 10) <= parseInt(prerequisite.value, 10) ) {
            return false;
          }

        } else if (prerequisite.operator === ">=") {

          if (parseInt(answer, 10) < parseInt(prerequisite.value, 10) ) {
            return false;
          }

        } else if (prerequisite.operator === "<") {

          if (parseInt(answer, 10) >= parseInt(prerequisite.value, 10) ) {
            return false;
          }

        } else if (prerequisite.operator === "<=") {

          if (parseInt(answer, 10) > parseInt(prerequisite.value, 10) ) {
            return false;
          }

        } else if (prerequisite.operator === "IN") {

          if (typeof(prerequisite.value) === "string") {

            // prerequisite_answer is the probands answer for the question
            // choice is the choice from the master question choices that matched the prerequisite_answer.master_question_choice_id
            const values_array = prerequisite.value.split(",");
            if (!values_array.includes(answer) ) {
              return false;
            }

          } else {
            // for some reason the prerequisite value is not a string
            // that can be split into an array
            return false;
          }

        } else if (prerequisite.operator === "NOT IN") {

          if (typeof(prerequisite.value) === "string") {

            // prerequisite_answer is the probands answer for the question
            // choice is the choice from the master question choices that matched the prerequisite_answer.master_question_choice_id
            const values_array = prerequisite.value.split(",");
            if (values_array.includes(answer) ) {
              return false;
            }

          } else {
            // for some reason the prerequisite value is not a string
            // that can be split into an array
            return false;
          }

        } else {
          // prerequisite operator does not equal one that we recognize
          return false;
        }

      } // end prerequisite answers if

    } // end question prerequisites loop

    return true;
  }

  checkMemberPrerequisites(master_question) {
    for (const prerequisite of master_question.member_question_prerequisites)  {

      let member_field_value = this.props.proband[prerequisite.member_field];

      if (typeof(member_field_value) === "string") {
        // do nothing
      } else if (typeof(member_field_value) === "number") {
        member_field_value += "";
      } else if (typeof(member_field_value) === "boolean") {
        if (member_field_value) {
          member_field_value = "true";
        } else {
          member_field_value = "false";
        }
      }

      if (!member_field_value) {
        return false;
      }

      if (prerequisite.operator === "==") {

        if (member_field_value !== prerequisite.value) {
           return false;
        }

      } else if (prerequisite.operator === "!=") {

        if (member_field_value === prerequisite.value) {
          return false;
        }

      } else if (prerequisite.operator === ">") {

        if (parseInt(member_field_value, 10) <= parseInt(prerequisite.value, 10) ) {
          return false;
        }

      } else if (prerequisite.operator === ">=") {

        if (parseInt(member_field_value, 10) < parseInt(prerequisite.value, 10) ) {
          return false;
        }

      } else if (prerequisite.operator === "<") {

        if (parseInt(member_field_value, 10) >= parseInt(prerequisite.value, 10) ) {
          return false;
        }

      } else if (prerequisite.operator === "<=") {

        if (parseInt(member_field_value, 10) > parseInt(prerequisite.value, 10) ) {
          return false;
        }

      } else if (prerequisite.operator === "IN") {

        if (typeof(prerequisite.value) === "string") {

          const temp_values = prerequisite.value.split(",");
          const values_array = temp_values.map(v => v.trim());
          if (!values_array.includes(member_field_value) ) {
            return false;
          }

        } else {
          return false;
        }

      } else if (prerequisite.operator === "NOT IN") {

        if (typeof(prerequisite.value) === "string") {

          const temp_values = prerequisite.value.split(",");
          const values_array = temp_values.map(v => v.trim());
          if (values_array.includes(member_field_value) ) {
            return false;
          }

        } else {
          return false;
        }

      } else {
        // prerequisite operator does not equal one that we recognize
        return false;
      }

    } // end member prerequisites loop

    return true;
  }

  checkDiseasePrerequisites(master_question) {
    const proband_diseases = this.props.proband_diseases;
    const proband_disease_skip_logics = this.props.proband_disease_skip_logics;

    for (const prerequisite of master_question.disease_question_prerequisites)  {

      const prerequisite_disease_id = parseInt(prerequisite.disease_id, 10);
      const prerequisite_age_diagnosed = prerequisite.age_diagnosed;
      const prerequisite_operator = prerequisite.operator;

      let found_disease = null;
      found_disease = proband_diseases.find((disease) => {
        return parseInt(disease.disease_id, 10) === prerequisite_disease_id;
      });

      if (prerequisite_operator === "!=" && !prerequisite_age_diagnosed) {
        if (!found_disease) return true;
        return false;
      }

      if (!found_disease) return false;

      // check and see if prerequisite_age_diagnosed is set
      // if not then return true its only checking if the member had/has disease
      if (!prerequisite_age_diagnosed) {
        return true;
      }

      const age_diagnosed = found_disease.age_diagnosed;
      if (prerequisite_operator === "==") {

        // even if this is an integer its saved as varchar in DB
        // so we can compare it as is
        if (age_diagnosed !== prerequisite_age_diagnosed) {
            return false;
        }

      } else if (prerequisite_operator === "!=") {

        // even if this is an integer its saved as varchar in DB
        // so we can compare it as is
        if (age_diagnosed === prerequisite_age_diagnosed) {
            return false;
        }

      } else if (prerequisite_operator === ">") {

        if (parseInt(age_diagnosed, 10) <= parseInt(prerequisite_age_diagnosed)) {
          return false;
        }

      } else if (prerequisite_operator === ">=") {

        if (parseInt(age_diagnosed, 10) < parseInt(prerequisite_age_diagnosed)) {
          return false;
        }

      } else if (prerequisite_operator === "<") {

        if (parseInt(age_diagnosed, 10) >= parseInt(prerequisite_age_diagnosed)) {
          return false;
        }

      } else if (prerequisite_operator === "<=") {

        if (parseInt(age_diagnosed, 10) > parseInt(prerequisite_age_diagnosed)) {
          return false;
        }

      } else if (prerequisite_operator === "IN") {

        if (typeof(prerequisite_age_diagnosed) === "string") {
          const temp_values = prerequisite_age_diagnosed.split(",");
          const values_array = temp_values.map(v => v.trim());

          // if value not in array then test has failed
          if (!values_array.includes(age_diagnosed)) {
            return false;
          }

        } else {
          return false;
        }

      } else if (prerequisite_operator === "NOT IN") {

        if (typeof(prerequisite_age_diagnosed) === "string") {
          const temp_values = prerequisite_age_diagnosed.split(",");
          const values_array = temp_values.map(v => v.trim());

          // if value in array then test has failed
          if (values_array.includes(age_diagnosed)) {
            return false;
          }

        } else {
          return false;
        }

      } else {
        // prerequisite operator does not equal one that we recognize
        return false;
      }


      const skip_logics = prerequisite.disease_skip_logic_question_prerequisites;
      if (skip_logics.length > 0) {

        // loop through skip logics and test the criteria
        for (let skip_logic of skip_logics) {

          let found_skip_logic = null;
          found_skip_logic = proband_disease_skip_logics.find((sl) => {
            return parseInt(sl.skip_logic_id, 10) === parseInt(skip_logic.skip_logic_id, 10);
          });

          if (!found_skip_logic) {
            return false;
          }

          // check skip logic criteria
          if (skip_logic.operator === "==") {

            if (found_skip_logic.answer !== skip_logic.skip_logic_answer) {
              return false;
            }

          } else if (skip_logic.operator === "!=") {

            if (found_skip_logic.answer === skip_logic.skip_logic_answer) {
              return false;
            }

          } else if (skip_logic.operator === ">") {

            if (parseInt(found_skip_logic.answer, 10) <= parseInt(skip_logic.skip_logic_answer, 10)) {
              return false;
            }

          } else if (skip_logic.operator === ">=") {

            if (parseInt(found_skip_logic.answer, 10) < parseInt(skip_logic.skip_logic_answer, 10)) {
              return false;
            }

          } else if (skip_logic.operator === "<") {

            if (parseInt(found_skip_logic.answer, 10) >= parseInt(skip_logic.skip_logic_answer, 10)) {
              return false;
            }

          } else if (skip_logic.operator === "<=") {

            if (parseInt(found_skip_logic.answer, 10) > parseInt(skip_logic.skip_logic_answer, 10)) {
              return false;
            }

          } else if (skip_logic.operator === "IN") {

            if (typeof(skip_logic.skip_logic_answer) === "string") {
              const temp_values = skip_logic.skip_logic_answer.split(",");
              const values_array = temp_values.map(v => v.trim());

              // if value not in array then test has failed
              if (!values_array.includes(found_skip_logic.answer)) {
                return false;
              }
            } else {
              return false;
            }

          } else if (skip_logic.operator === "NOT IN") {

            if (typeof(skip_logic.skip_logic_answer) === "string") {
              const temp_values = skip_logic.skip_logic_answer.split(",");
              const values_array = temp_values.map(v => v.trim());

              // if value in array then test has failed
              if (values_array.includes(found_skip_logic.answer)) {
                return false;
              }
            } else {
              return false;
            }

          } else {
            // prerequisite operator does not equal one that we recognize
            return false;
          }

        } // end skip logic loop

      } // end skip logic if statement

    } // end disease prerequisites loop

    return true;
  }

  render() {

    const page_content = this.buildContent();

    return (
      <div>
        {page_content}
      </div>
    );

  }
}

export default PatientSurveyFieldCanvas;
